<div class="contact" [class.active]="active">
  <div class="gContact">
    <div (click)="active=!active" class="text-center id-db">{{ item.id }}</div>
    <div (click)="active=!active" class="name">{{ item.fullname }}</div>
    <div (click)="active=!active" class="text-center doc-type">{{item.document_type}}</div>
    <div (click)="active=!active" class="text-center id">
      <span class="type">{{item.document_type}}</span>
      {{item.document_number}}
    </div>
    <div (click)="active=!active" class="text-center signature">{{ item.signature_date }}</div>
    <div (click)="active=!active" class="text-center status">
      <app-status [status]="item.signature_status"></app-status>
    </div>
    <div class="box-center actions">
      <app-actions [contact]="item"
                   [status]="item.signature_status"
                   (viewDoc)="showDocument()"
                   (edit)="editContact()"></app-actions>
    </div>
  </div>
  <div class="info" [class.active]="active">
    <div class="tel">
      <label class="antialiased">
        <i class="fa fa-phone"></i>
        <span>TELÉFONO</span>
      </label>
      <dd class="antialiased">{{ item.phone }}</dd>
    </div>
    <div class="dir">
      <label class="antialiased">
        <i class="fa fa-map-marker"></i>
        <span>DIRECCIÓN</span>
      </label>
      <dd class="antialiased">{{ item.address }}</dd>
    </div>
    <div class="email">
      <label class="antialiased">
        <i class="fa fa-envelope"></i>
        <span>CORREO</span>
      </label>
      <dd class="antialiased">{{ item.email }}</dd>
    </div>
    <div class="box-left action">
      <div class="icon" (click)="sendEmail()">
        <img height="20" src="assets/icons/mensaje.svg">
      </div>
    </div>
  </div>
</div>
<style>
  .gContact {
    display: grid;
    grid-template-columns: 8% 30% 5% 14% 14% 14% 12%;
    cursor: pointer;
    align-items: center;
    border-top: 1px solid #eaeaea;
  }

  .contact.active .gContact {
    border-bottom: 1px solid #eaeaea;
  }

  .gContact > div {
    padding: 1.25rem .5rem;
    font-size: .85rem;
    color: #6D6E71;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .info {
    background-color: #f0f1f4;
    opacity: 0;
    padding-left: 1rem;
    max-height: 0;
    transition: .2s ease-in-out all;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 11%;
    grid-gap: 1rem;
    max-width: 100%;
  }

  .info.active {
    height: auto;
    max-height: 140px;
    opacity: 1;
    padding: 1.5rem 1rem;
    padding-right: 0;
  }

  label {
    color: #6D6E71;
    font-size: .8rem;
  }

  label i {
    margin-right: .5rem;
    width: 15px;
    text-align: center;
  }

  dd {
    padding-left: calc(15px + .5rem);
    font-size: .9rem;
    margin-top: .5rem;
  }

  .gContact .actions {
    padding: .25rem 0;
  }

  .icon {
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    display: flex;
    border: 1px solid #eaeaea;
    border-radius: 50%;
    transition: .2s;
    background-color: #fff;
  }

  .icon:active {
    background: rgba(0, 0, 0, 0.075);
  }

  .contact {
    overflow: hidden;
  }

  .contact.active {
    margin-bottom: 1rem;
  }

  .contact.active .gContact {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }

  .type {
    display: none;
  }

  @media (max-width: 750px) {
    .contact {
      padding: 0;
      border-bottom: 3px solid #eaeaea;
    }

    .gContact {
      border-top: 0;
      padding: .5rem;
      padding-top: .75rem;
      padding-bottom: 0;
    }

    .gContact .name {
      grid-area: name;
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: .25rem;
      color: #333;
      font-size: .9rem;
    }

    .gContact .doc-type {
      display: none;
    }

    .gContact .name:before {
      display: inline-block;
      content: 'Nombre:';
      margin-right: .25rem;
      color: #6D6E71;
      font-size: .85rem;
    }

    .gContact .id-db {
      grid-area: iddb;
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: .25rem;
      text-align: left;
      color: #333;
      font-size: .9rem;
    }

    .gContact .id-db:before {
      display: inline-block;
      content: 'ID:';
      margin-right: .25rem;
      color: #6D6E71;
      font-size: .85rem;
    }

    .gContact .id {
      grid-area: id;
      padding-top: 0;
      padding-bottom: 0;
      text-align: left;
      margin-bottom: .25rem;
      color: #333;
      font-size: .9rem;
    }

    .gContact .id .type {
      display: inline;
    }

    .gContact .id:before {
      display: inline-block;
      content: 'Documento:';
      margin-right: .25rem;
      color: #6D6E71;
      font-size: .85rem;
    }

    .gContact .signature {
      grid-area: signature;
      padding-top: 0;
      padding-bottom: 0;
      text-align: left;
      margin-bottom: .25rem;
      color: #333;
      font-size: .9rem;
    }

    .gContact .signature:before {
      display: inline-block;
      content: 'Fecha firma:';
      margin-right: .25rem;
      color: #6D6E71;
      font-size: .85rem;
    }

    .gContact .status {
      grid-area: status;
      padding: 1rem 0;
      font-weight: 600;
    }

    .gContact .actions {
      grid-area: actions;
      justify-content: flex-end;
    }

    .gContact {
      grid-template-columns: 1fr 1fr;
      grid-template-areas: 'iddb iddb' 'name name' 'id id' 'signature signature' 'status actions';
    }

    .info .tel {
      grid-area: tel;
    }

    .info .dir {
      grid-area: dir;
    }

    .info .email {
      grid-area: email;
    }

    .info .action {
      grid-area: action;
    }

    .info {
      grid-template-columns: 1fr max-content;
      grid-template-areas: 'tel action' 'dir dir' 'email email';
    }

    .info.active {
      padding: 1rem;
      padding-right: .75rem;
      max-height: 330px;
    }

    .info dd {
      margin-top: .25rem;
    }

    .info label {
      font-size: .7rem;
    }

  }

</style>
