import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../services/api.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ConfirmComponent} from '../modals/confirm/confirm.component';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html'
})
export class ActionsComponent implements OnInit {
  @Input() contact: any;
  @Input() status: number;
  @Output() viewDoc: EventEmitter<void> = new EventEmitter();
  @Output() edit: EventEmitter<void> = new EventEmitter();

  constructor(private api: ApiService,
              private dialog: MatDialog,
              private snack: MatSnackBar) {
  }

  ngOnInit() {
  }

  editContact(b) {
    if (b) {
      this.edit.emit();
    }
  }

  showDocument(b) {
    if (b) {
      this.viewDoc.emit();
    }
  }

  sendEmail(): void {
    const dialog = this.dialog.open(ConfirmComponent, {
      width: '400px',
      data: {
        content: `¿Desea enviar el correo para solicitar la firma?`,
        ok: 'Aceptar',
        cancel: 'Cancelar'
      }
    });
    dialog.afterClosed().subscribe((status: boolean) => {
      if (status) {
        this.api.sendSignEmail(this.contact.id).subscribe({
          next: () => {
            this.snack.open('Se ha enviado un correo con instrucciones para realizar la firma', 'Aceptar', {
              duration: 3000
            });
          },
          error: (error: HttpErrorResponse) => {
            let message = '';
            if (error.status === 0) {
              message = `Se ha presentado un error de red. [Código de error ${error.status}]`;
            } else {
              if (error.error.message) {
                message = error.error.message;
              } else {
                message = `Se ha presentado un error. [Código de error ${error.status}]`;
              }
            }
            this.snack.open(message, 'Aceptar', {
              duration: 3000
            });
          }
        });
      }
    });

  }
}
