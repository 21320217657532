<main>
  <div class="header-wrapper">
    <app-header-main [desktop]="true"></app-header-main>
  </div>
  <mat-tab-group mat-stretch-tabs (selectedIndexChange)="tabChange($event)">
    <mat-tab label="APROBADOS">
      <section class="actions box-left double-spaced back-gris">
        <div class="search">
          <app-search></app-search>
        </div>
        <div>
          <button *ngIf="!filtrosOpen" mat-stroked-button color="primary" class="box-center" (click)="filtrosOpen=true">
            <i class="fal fa-filter"></i>
            <span>Ver filtros</span>
          </button>
          <button *ngIf="filtrosOpen" mat-stroked-button color="primary" class="box-center" (click)="clearFilters()">
            <i class="fal fa-sync"></i>
            <span>Refrescar filtros</span>
          </button>
        </div>
        <div>
          <button mat-stroked-button
                  color="primary"
                  class="box-center"
                  (click)="download()">
            <i class="fal fa-arrow-to-bottom"></i>
            <span>Descargar</span>
          </button>
        </div>
      </section>
      <div [hidden]="!filtrosOpen">
        <app-filtros [approved]="approved" (times)="filtrosOpen=false"></app-filtros>
      </div>
      <ng-container *ngIf="!loading">
        <app-tabla-backoffice [contacts]="contacts"
                              [page]="page"
                              [total]="total">
        </app-tabla-backoffice>
      </ng-container>
    </mat-tab>
    <mat-tab label="POR APROBAR" *ngIf="canApprove">
      <section class="actions box-left double-spaced back-gris">
        <div class="search">
          <app-search></app-search>
        </div>
        <div>
          <button *ngIf="!filtrosOpen" mat-stroked-button color="primary" class="box-center" (click)="filtrosOpen=true">
            <i class="fal fa-filter"></i>
            <span>Ver filtros</span>
          </button>
          <button *ngIf="filtrosOpen" mat-stroked-button color="primary" class="box-center" (click)="clearFilters()">
            <i class="fal fa-sync"></i>
            <span>Refrescar filtros</span>
          </button>
        </div>
        <div>
          <button mat-stroked-button
                  color="primary"
                  class="box-center"
                  (click)="download()">
            <i class="fal fa-arrow-to-bottom"></i>
            <span>Descargar</span>
          </button>
        </div>
      </section>
      <div [hidden]="!filtrosOpen">
        <app-filtros [approved]="approved" (times)="filtrosOpen=false"></app-filtros>
      </div>
      <ng-container *ngIf="!loading">
        <app-tabla-no-aprobados [contacts]="contacts"
                                [page]="page"
                                [total]="total"
                                (update)="refreshContacts()">
        </app-tabla-no-aprobados>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
  <div style="margin: 20%" *ngIf="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <div class="text-center">
      <small>Cargando...</small>
    </div>
  </div>

</main>
<a [href]="url" #anchor download="reporte.xlsx" target="_blank" style="display: none"></a>
<style>
  section.actions {
    padding: 0 1rem;
  }

  .search {
    min-width: 600px;
  }

  button {
    min-width: 150px;
    background-color: #fff;
  }

  button i {
    margin-right: .5rem;
  }

    main{
        position: relative;
    }

    .header-wrapper{
        position: sticky;
        top:0;
        z-index: 10;
    }

</style>
