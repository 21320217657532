import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BackofficeService} from '../../services/backoffice.service';
import {FiltrosComponent} from './filtros/filtros.component';
import {SafeUrl} from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-backoffice',
  templateUrl: 'backoffice.component.html'
})
export class BackofficeComponent implements OnInit {

  filtrosOpen: boolean;
  contacts: any[];
  public url: SafeUrl;
  public page: number;
  public total: number;
  @ViewChild(FiltrosComponent, {static: false})
  public filters: FiltrosComponent;
  @ViewChild('anchor', {static: false})
  public link: ElementRef;
  private _loading: boolean;
  public approved = true;

  constructor(private bs: BackofficeService, private userService: UserService,
              private snack: MatSnackBar) {
    this.filtrosOpen = false;
    this._loading = false;
  }

  ngOnInit() {
    this.bs.setFilters();
    this.bs.contacts.subscribe((data: any) => {
      this.contacts = data.contacts;
      this.page = data.page - 1;
      this.total = data.total;
    });
  }

  toggleFiltros() {
    this.filtrosOpen = !this.filtrosOpen;
  }

  public clearFilters() {
    if (this.filters) {
      this.filters.clear();
    }
  }

  get loading(): boolean {
    return this.bs.loading || this._loading;
  }

  public download(): void {
    if (this.filters) {
      this._loading = true;
      this.bs.download(this.filters.value).subscribe((url: SafeUrl) => {
        this._loading = false;
        this.url = url;
        this.forceDownload();
      }, () => {
        this._loading = false;
        this.snack.open('Se presento un error generando el informe', 'Aceptar', {
          duration: 3000
        });
      });
    }
  }

  public forceDownload(): void {
    setTimeout(() => {
      this.link.nativeElement.click();
    }, 500);
  }

  refreshContacts() {
    this.bs.getContacts();
  }

  tabChange(ev: number) {
    this.approved = (ev === 0);

    setTimeout(() => {
      this.clearFilters();
    }, 50);
  }

  get canApprove() {
    return this.userService.user.can_approve;
  }
}
