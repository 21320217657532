import {Component, OnInit} from '@angular/core';
import {ApiService, IContactList} from '../../services/api.service';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';
import {ContactService} from '../../services/contact.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html'
})
export class MainComponent implements OnInit {

  contacts: Array<IContactList> = [];

  search = '';
  loading = true;

  index = 0;
  page = 1;
  done = false;

  filters = {order: '', period: ''};
  first: boolean;
  count: any;

  constructor(protected api: ApiService, private userService: UserService,
              private contactService: ContactService, private router: Router) {
    this.first = false;
  }

  ngOnInit() {
    this.getContacts();
    this.api.getUserCount(this.userService.user.username).subscribe((count) => {
      this.count = count;
    });
  }

  doSearch(search: string) {
    this.search = search;
    this.resetPage();
    this.getContacts(this.search);
  }

  getContacts(search?: string, page?: number) {
    if (this.done) {
      return;
    }
    this.loading = true;
    if (this.index === 0) {
      this.getAllContacts(search, page);
    }
    if (this.index === 1) {
      this.getMyContacts(search, page);
    }
  }

  private getAllContacts(search?: string, page?: number) {
    this.api
      .getContacts(search, page)
      .subscribe(data => {
        this.addContacts(data.contacts, data.pagination);
      });
  }

  private getMyContacts(search?: string, page?: number) {
    this.api
      .getUserContacts(this.userService.user.username, search, page, this.filters)
      .subscribe(data => {
        this.addContacts(data.contacts, data.pagination);
      });
  }

  private addContacts(contacts, pagination) {
    this.first = true;
    this.contacts.push(...contacts);
    this.done = pagination.page === pagination.num_pages;
    this.page = pagination.page;
    this.loading = false;
  }

  tabChange(index: number) {
    this.resetPage();
    this.index = index;
    this.getContacts(this.search);
  }

  applyFilters() {
    this.resetPage();
    this.getContacts(this.search);
  }

  scrollPage(event) {
    const el = event.target;
    if (!this.loading && !this.done) {
      if (el.scrollTop >= (el.scrollHeight - el.offsetHeight)) {
        this.getContacts(this.search, this.page + 1);
      }
    }
  }

  clearFilters() {
    this.filters.order = '';
    this.filters.period = '';
    this.search = '';
    this.resetPage();
    this.getContacts();
  }

  private resetPage() {
    this.contacts = [];
    this.done = false;
    this.page = 1;
  }

  createContact() {
    this.contactService.clear();
    const qp = this.search ? {data: this.search} : {};
    this.router.navigate(['/create'], {queryParams: qp});
  }
}
