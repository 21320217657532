<main class="box-center">
    <div class="login-area">
        <div class="pad10 text-center">
            <img height="170" class="logo m-auto" src="assets/icons/logo.svg">
            <h1>Habeas Data App</h1>
<!--            <small>{{ version }}</small>-->
        </div>

        <div class="text-center pad-top-2">
            {{ message }}
        </div>
    </div>
</main>
<style>
    .login-area {
        width: 300px;
        margin-bottom: 100px;
    }

    h1 {
        margin-bottom: 0.5rem;
    }

    .error {
        position: absolute;
        margin: 0 auto;
        max-width: 500px;
        background-color: #ffcdd2;
        border: solid 1px #ff80ab;
        border-radius: 3px;
        color: #ff253a;
        padding: .6rem 1rem;
        bottom: 100px;
        cursor: pointer;
    }
</style>
