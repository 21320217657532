import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {switchMap} from 'rxjs/operators';
import jwtDecode from 'jwt-decode';
import {environment} from '../../environments/environment';
import {ApiService} from './api.service';

@Injectable({providedIn: 'root'})
export class UserService {
  private readonly TOKEN_KEY = 'current-user-jwt';
  public user: any;

  constructor(private http: HttpClient,
              private api: ApiService) {
  }

login(token){
		
	this.saveToken(token.access);
	this.getUser();
	this.checkToken();
}

  getUser() {
    if (this.TOKEN) {
      try {
        this.user = jwtDecode(this.TOKEN);
      } catch (e) {
        console.error(e);
      }
    }
    return this.user;
  }

  logout() {
    this.user = null;
    localStorage.removeItem(this.TOKEN_KEY);
    localStorage.clear();
  }

  private saveToken(token: string) {
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  get TOKEN() {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  public checkToken(): Promise<void> {
    return new Promise((resolve) => {
      this.http.get(`${environment.apiRoot}/api/check`).subscribe({
        next: (response: any) => {
          this.getUser();
          this.api.version = response.habeas;
          this.api.contactTypes = response.contact_types;
          this.api.documents = response.documents;
          resolve();
        },
        error: (e) => {
          console.error(e);
          this.logout();
          resolve();
        }
      });
    });
  }
}
