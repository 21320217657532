import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[appNumeric]'
})
export class NumericDirective {
    @Input() decimals = 0;

    private specialKeys = [
        'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'
    ];

    private check(value: string, decimals: number) {
        return String(value).match(new RegExp(/^\d+$/));
    }

    constructor(private el: ElementRef) {
    }

    @HostListener('keydown', [ '$event' ])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        // Do not use event.keycode this is deprecated.
        // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
        const current: string = this.el.nativeElement.value;
        const next: string = current.concat(event.key);
        if ( next && !this.check(next, this.decimals) ) {
            event.preventDefault();
        }
    }
}
