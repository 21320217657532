import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {MatDialog} from '@angular/material/dialog';
import {AcceptComponent} from '../../../components/modals/accept.component';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html'
})
export class RecoveryComponent implements OnInit {
  public form: FormGroup;

  constructor(private api: ApiService,
              private dialog: MatDialog,
              private router: Router,
              public fb: FormBuilder,
              public snack: MatSnackBar) {
  }

  ngOnInit() {
    if (environment.digital) {
      this.router.navigate(['/error']);
    }
    this.form = this.fb.group({
      initials: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern(/^[A-Za-z]+$/)
      ]]
    });
  }

  recover() {
    if (this.form.valid) {
      const {initials} = this.form.value;
      this.api
        .recoverPassword(initials.toUpperCase())
        .subscribe({
          next: (data: any) => {
            this.dialog.open(AcceptComponent, {
              width: '400px',
              data: {
                title: 'Reestablecer contraseña',
                content: data.message,
                button: 'Aceptar'
              }
            });
          },
          error: (err) => {
            this.dialog.open(AcceptComponent, {
              width: '400px',
              data: {
                title: 'Error',
                content: 'Ocurrió un error enviando el correo de recuperación.',
                button: 'Aceptar'
              }
            });
          }
        });
    } else {
      this.snack.open('Iniciales no validas', 'Aceptar', {
        duration: 3000
      });
    }
  }
}
