<main class="box-center">
  <div class="login-area">
    <div class="pad10 text-center">
      <img height="100" class="logo m-auto" src="assets/icons/logo.svg" style="margin-bottom: 20px;">
      <h1>{{appName}}</h1>
      <small>{{version}}</small>
    </div>
    <form [formGroup]="form" [hidden]="loading" (ngSubmit)="signIn()">
      <div>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Iniciales Novo Nordisk</mat-label>
          <input matInput placeholder="Iniciales Novo Nordisk" formControlName="username">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Contraseña</mat-label>
          <input matInput type="password" placeholder="Contraseña" formControlName="password">
        </mat-form-field>
      </div>
      <button type="submit" mat-flat-button color="primary" class="btn w-100">
        <i class="fal fa-sign-in"></i>
        <span>Ingresar</span>
      </button>
      <div class="text-center pad-top-2">
        <p class="blue link" [routerLink]="'/recovery'">Recuperar contraseña</p>
      </div>
    </form>
    <div class="text-center" *ngIf="loading">
      <mat-progress-bar mode="indeterminate">
      </mat-progress-bar>
      <small>Cargando...</small>
    </div>
  </div>
  <div class="error" *ngIf="error" (click)="error = ''">{{ error }}</div>
</main>
<style>
  .login-area {
    width: 300px;
    margin-bottom: 100px;
  }

  h1 {
    margin-bottom: 0.5rem;
  }

  .error {
    position: absolute;
    margin: 0 auto;
    max-width: 500px;
    background-color: #ffcdd2;
    border: solid 1px #ff80ab;
    border-radius: 3px;
    color: #ff253a;
    padding: .6rem 1rem;
    bottom: 100px;
    cursor: pointer;
  }
</style>
