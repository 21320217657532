import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header-back',
  templateUrl: './header-back.component.html'
})
export class HeaderBackComponent implements OnInit {

  @Input() title: string;
  @Input() back: string;
  @Input() hidden = false;
  @Output()
  public action = new EventEmitter();
  @Input() noBackButton: boolean;
  @Input() center = false;

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  onAction(): void {
    this.action.emit();
    if (this.back !== null) {
      this.router.navigate([`/${this.back}`]);
    }
  }
}
