import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-modal-accept',
    templateUrl: 'accept.component.html'
})
export class AcceptComponent {
 constructor(
     public dialogRef: MatDialogRef<AcceptComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any
 ) {}

 click() {
     this.dialogRef.close();
 }
}
