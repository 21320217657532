<section class="filtros double-spaced">
    <ng-container *ngIf="approved">
        <mat-form-field class="w-100" [formGroup]="form">
            <mat-label>Representante</mat-label>
            <mat-select formControlName="rep">
                <mat-option [value]="undefined">
                    Todos
                </mat-option>
                <mat-option *ngFor="let option of data.reps"
                            [value]="option">
                    {{option.initials}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="w-100" [formGroup]="form">
            <mat-label>Unidad de negocio</mat-label>
            <mat-select formControlName="business">
                <mat-option [value]="undefined">
                    Todos
                </mat-option>
                <mat-option *ngFor="let option of data.business"
                            [value]="option">
                    {{option.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="w-100" [formGroup]="form">
            <mat-label>Manager</mat-label>
            <mat-select formControlName="manager">
                <mat-option [value]="undefined">
                    Todos
                </mat-option>
                <mat-option *ngFor="let option of data.managers"
                            [value]="option">
                    {{option.initials}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="w-100" [formGroup]="form">
            <mat-label>Estado</mat-label>
            <mat-select formControlName="status">
                <mat-option [value]="undefined">
                    Todos
                </mat-option>
                <mat-option *ngFor="let option of data.status"
                            [value]="option">
                    {{option.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="w-100" [formGroup]="form">
            <mat-label>Tipo de contacto</mat-label>
            <mat-select formControlName="contactType">
                <mat-option [value]="undefined">
                    Todos
                </mat-option>
                <mat-option *ngFor="let option of data.contactTypes"
                            [value]="option">
                    {{option.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="w-100" [formGroup]="form">
            <mat-label>Subtipo</mat-label>
            <mat-select formControlName="contactSubType">
                <mat-option [value]="undefined">
                    Todos
                </mat-option>
                <mat-option *ngFor="let option of subTypes"
                            [value]="option">
                    {{option.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
    <mat-form-field class="w-100" [formGroup]="form">
        <mat-label>Periodo</mat-label>
        <mat-select formControlName="period">
            <mat-option [value]="undefined">
                Todos
            </mat-option>
            <mat-option [value]="1">
                Hoy
            </mat-option>
            <mat-option [value]="2">
                Semana Actual
            </mat-option>
            <mat-option [value]="3">
                Mes Actual
            </mat-option>
            <mat-option [value]="4">
                Año actual
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="w-100" [formGroup]="form" *ngIf="!approved">
        <mat-label>Representante</mat-label>
        <mat-select formControlName="event">
            <mat-option [value]="undefined">
                Todos
            </mat-option>
            <ng-container *ngFor="let option of data.events">
                <mat-option *ngIf="option" [value]="option">
                    {{ option }}
                </mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>

    <div class="times box-right" (click)="cerrar()">
        <span>CERRAR FILTROS</span>
        <i class="fal fa-times-circle"></i>
    </div>
</section>
<style>
    .filtros {
        padding: 1.75rem 1rem 0 1rem;
        display: flex;
        justify-content: stretch;
        position: relative;
        border-top: 1px solid #eaeaea;
        border-bottom: 1px solid #eaeaea;

    }

    .form-group {
        flex: 100%;
    }

    .form-group label {
        display: block;
        font-size: .8rem;
        margin-bottom: .25rem;
        color: #6D6E71;
    }

    .form-group select {
        width: 100%;
    }

    .filtros .times {
        position: absolute;
        top: 0.5rem;
        right: 1rem;
        font-size: .75rem;
        cursor: pointer;
        color: #004D95;
    }

    .filtros .times i {
        margin-left: .25rem;

    }

</style>
