<section>
    <div class="gHeadTable">
        <div>ID</div>
        <div>Nombre</div>
        <div>Tipo</div>
        <div>Documento</div>
        <div>Fecha firma</div>
        <div>Estado firma</div>
    </div>
    <ng-content></ng-content>
</section>
<style>
    section {
        padding: 0 1rem;
    }

    .gHeadTable{
        display: grid;
        grid-template-columns: 8% 30% 5% 14% 14% 14% 12%;
        height:50px;
        align-items: center;
        background: linear-gradient(to bottom, #0067A9 -10%, #0b1966 100%);
        font-size: .9rem;
        color:#fff;
        text-align: center;
        border-radius: 5px 5px 0 0;
        position: sticky;
        top:0;
    }

    .gHeadTable>div{
        padding:.5rem;
    }

    @media(max-width: 750px){
        .gHeadTable{
            display: none;
        }

        section{
            padding:0;
        }
    }

</style>
