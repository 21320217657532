import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {HttpBackend, HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ContactService} from './contact.service';
import {ApiService} from './api.service';

@Injectable()
export class SignGuard implements CanActivate {
  private http: HttpClient;

  constructor(handler: HttpBackend, private cs: ContactService, private apiService: ApiService,
              private router: Router) {
    this.http = new HttpClient(handler);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (environment.digital) {
      this.router.navigate(['/error']);
      return of(false);
    }
    return this.http.get(`${environment.apiRoot}/api/sign-by-email`, {
      headers: {
        Authorization: `Bearer ${route.queryParamMap.get('token') || ''}`
      }
    }).pipe(map((response: any) => {
      this.apiService.signToken = route.queryParamMap.get('token');
      this.cs.saveLocalContact(response.contact);
      this.apiService.version = response.habeas;
      return true;
    }), catchError((error: HttpErrorResponse) => {
      this.apiService.errorMessages = error.error.errors;
      this.router.navigate(['/error']);
      return of(false);
    }));
  }
}
