import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ContactService} from '../../services/contact.service';
import {UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ApiService} from '../../services/api.service';
import {AcceptComponent} from '../../components/modals/accept.component';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-event-create',
  templateUrl: 'event-create.component.html'
})
export class EventCreateComponent implements OnInit {

  contact = {
    firstName: '', lastName: '', email: '', docType: 'cc', docNumber: '', phone: '', address: ''
  };
  public form: UntypedFormGroup;

  private emailSignature = false;
  private event = '';
  public success = false;
  public subTypes: Array<any>;
  public subTypeLabel: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              public cs: ContactService,
              private fb: UntypedFormBuilder,
              private snack: MatSnackBar,
              private api: ApiService,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    this.event = this.route.snapshot.paramMap.get('event');
    if (!this.event) {
      this.api.errorMessages = ['Lo sentimos, el enlace ingresado es inválido.'];
      this.router.navigate(['/error']);
    }

    const savedEvent = localStorage.getItem('hbnn-current-event');
    let current = null;

    const find = this.api.contactTypes.find((i: any) => {
      return current && current.contact_type && current.contact_type.id === i.id;
    });

    this.subTypeLabel = find ? find.subtype_name : '';
    this.subTypes = find ? find.subtypes : [];

    if (savedEvent === this.event) {
      current = this.cs.loadLocalContact();
    } else {
      localStorage.setItem('hbnn-current-event', this.event);
    }

    const findSub = this.subTypes.find((i: any) => current && current.contact_subtype && current.contact_subtype.id === i.id);
    this.form = this.fb.group({
      id: current ? current.id : undefined,
      name: [current ? current.name : '', Validators.required],
      lastname: [current ? current.lastname : '', Validators.required],
      document_type: [current ? current.document_type : undefined, Validators.required],
      document_number: [current ? current.document_number : '', Validators.required],
      email: [current ? current.email : '', [Validators.required, Validators.email]],
      phone: [current ? current.phone : '', Validators.required],
      address: [current ? current.address : '', Validators.required],
      contact_type: [find ? {id: find.id, name: find.name} : undefined, Validators.required],
      contact_subtype: [findSub ? {id: findSub.id, name: findSub.name} : undefined, []]
    });

    this.form.get('contact_type').valueChanges.subscribe((value: any) => {
      this.subTypes = [];
      const ct = this.api.contactTypes.find((i: any) => !!value && value.id === i.id);
      setTimeout(() => {
        this.subTypes = ct ? ct.subtypes : [];
        this.subTypeLabel = ct ? ct.subtype_name : '';
      }, 0.5);
      this.form.patchValue({
        contact_subtype: undefined
      }, {
        emitEvent: false
      });
    });
  }

  goToSignature() {
    if (this.form.valid) {
      this.cs.saveLocalContact(this.form.value);
      this.api.checkDocument(
          this.form.value.document_type,
          this.form.value.document_number,
      ).subscribe(res => {
        this.router.navigate(['/evento', 'firmar', this.event]);
      }, err => {
        this.api.errorMessages = ['Usted ya cuenta con un consentimiento informado.'];
        this.router.navigate(['/error']);
      });
    } else {
      const errors = this.getErrors();
      let message = 'Formulario no válido.';

      for (const item of errors) {
        if (item.control === 'email') {
          message += ' El email no es válido. Verifique que no contenga espacios.';
        }
        if (item.control === 'name') {
          message += ' El nombre es obligatorio.';
        }
        if (item.control === 'lastname') {
          message += ' El nombre es obligatorio.';
        }
        if (item.control === 'document_type') {
          message += ' El tipo de documento es obligatorio.';
        }
        if (item.control === 'document_number') {
          message += ' El número de documento es obligatorio.';
        }
        if (item.control === 'phone') {
          message += ' El número de teléfono es obligatorio.';
        }
        if (item.control === 'address') {
          message += ' La dirección es obligatoria.';
        }
        if (item.control === 'contact_type') {
          message += ' El tipo de contacto es obligatorio.';
        }
      }

      this.snack.open(message, 'Aceptar', {
        duration: 3000
      });
    }
    this.emailSignature = false;
  }

  getErrors(): Array<{control: string, error: string, value: string}> {
    const result = [];
    Object.keys(this.form.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          result.push({
            control: key,
            error: keyError,
            value: controlErrors[keyError]
          });
        });
      }
    });

    return result;
  }

  get documents(): Array<any> {
    return this.api.documents;
  }

  get contactTypes(): Array<any> {
    return this.api.contactTypes;
  }

  get contactType(): any {
    return this.form ? this.form.value.contact_type : undefined;
  }

  compareItem(o1: any, o2: any): boolean {
    return o1 && o2 && o1.id === o2.id;
  }

  public showAddressMessage(): void {
    this.dialog.open(AcceptComponent, {
      width: '400px',
      data: {
        content: `<p>Digite la dirección siguiendo estos formatos de ejemplo:</p>
                    <ul>
                    <li>Cl. 125 # 19-24</li>
                    <li>Cr. 27 # 30-16</li>
                    <li>Dg. 9 # 30-29</li>
                    <li>Tv. 123 # 23-04</li>
                    </ul>`,
        button: 'Aceptar'
      }
    });
  }

  get siteKey() {
    return environment.reCaptcha ? environment.reCaptcha.siteKey : '';
  }

  resolved(ev: string) {
    this.api.verifyReCaptcha(ev).subscribe(d => {
      this.success = d.success;
    });
  }
}
