<section class="actions" *ngIf="showActions">
    <button mat-stroked-button color="primary" class="box-center" (click)="approve()">
      <span>Aprobar</span>
    </button>
    <button mat-stroked-button color="primary" class="box-center" (click)="reject()">
      <span>Rechazar</span>
    </button>
</section>
<section>
  <div class="gHeadTable">
    <div><mat-checkbox [(ngModel)]="selectAll" (change)="selectContacts($event)"></mat-checkbox></div>
    <div class="text-left">Nombre</div>
    <div class="text-left">Apellidos</div>
    <div>Documento</div>
    <div>Correo</div>
    <div>Fecha firma</div>
    <div>Representante</div>
    <div>Estado</div>
  </div>

  <div class="gContact" *ngFor="let c of contacts">
    <div><mat-checkbox [(ngModel)]="c.checked" (change)="checkSelected()"></mat-checkbox></div>
    <div class="text-left">{{ c.name }}</div>
    <div class="text-left">{{ c.lastname }}</div>
    <div class="text-center">{{ c.document_type }} {{ c.document_number }}</div>
    <div class="text-center">{{ c.email }}</div>
    <div class="text-center">{{ getDate(c.last_signature_date) | date:'dd/MM/yyyy' }}</div>
    <div class="text-center">{{ c.event.toUpperCase() || '- Sin evento -' }}</div>
    <div class="text-center box-center"><app-status [status]="4"></app-status></div>
  </div>

  <mat-paginator [length]="total" [pageSize]="50" [pageIndex]="page" (page)="next($event)">
  </mat-paginator>
</section>
<style>
  section {
    padding: 1rem;
  }

  .gHeadTable {
    display: grid;
    grid-template-columns: 3% 18% 18% 11% 15% 10% 15% 10%;
    height: 50px;
    align-items: center;
    background: linear-gradient(to bottom, #0067A9 -10%, #0b1966 100%);
    font-size: .9rem;
    color: #fff;
    text-align: center;
    border-radius: 5px 5px 0 0;
    position: sticky;
    top: 50px;
  }

  .gHeadTable > div {
    padding: .5rem;
  }

  .gContact {
    display: grid;
    grid-template-columns: 3% 18% 18% 11% 15% 10% 15% 10%;
    cursor: pointer;
    align-items: center;
    border-top: 1px solid #eaeaea;
  }

  .gContact > div {
    padding: 1.25rem .5rem;
    font-size: .85rem;
    color: #6D6E71;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  button {
    min-width: 150px;
    background-color: #fff;
  }

  section.actions {
    padding: 1rem 1rem 0;
  }

  section.actions button {
      margin-left: 1rem;
  }
</style>
