<div class="box-left wrap">
    <div *ngIf="status===3" class="status ok">
        ⬤ FIRMADO
    </div>
    <div *ngIf="status===1" class="status pending">
        ⬤ ANTERIOR
    </div>
    <div *ngIf="status===0" class="status no-signature">
        ⬤ SIN FIRMA
    </div>
    <div *ngIf="status===4" class="status pending">
        ⬤ PENDIENTE
    </div>
</div>
<style>
    .wrap{
        margin-left:.5rem;
    }
    .status {
        font-size: .75rem;
        white-space: nowrap;
    }

    .status.ok {
        color: #27ae60;
    }

    .status.pending {
        color: #E28228;
    }

    .status.no-signature {
        color: #e2282e;
    }
</style>
