<main>
    <app-header-back title="Recuperar contraseña" back="/login"></app-header-back>
    <div class="box-center content">
        <div class="w400 text-center">
            <h1>Recuperar contraseña</h1>
            <p>Para recuperar la contraseña ingrese las iniciales de usuario de Novo Nordisk y recibirá con correo con
                instrucciones.</p>
            <div class="pad10 w300 m-auto" *ngIf="form" [formGroup]="form">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Iniciales Novo Nordisk</mat-label>
                    <input matInput placeholder="Iniciales Novo Nordisk" formControlName="initials">
                </mat-form-field>
                <button mat-flat-button color="primary" class="btn w-100" (click)="recover()">
                    <span>Recuperar Contraseña</span>
                </button>
            </div>
            <div class="text-center pad-top-1">
                <p class="blue link" [routerLink]="'/login'"><i class="fal fa-angle-left"></i> Volver</p>
            </div>
        </div>
    </div>
</main>
<style>
    main{
        display: flex;
        flex-direction: column;
    }

    .content{
        flex: 100%;
        margin-bottom:100px;
    }

    @media(max-width: 600px){
        .content{
            padding-left:1rem;
            padding-right:1rem;
        }
    }

</style>
