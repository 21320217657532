<main class="main">
    <app-header-back [back]="'*'" title="Ver documento firmado" (action)="contactService.clear()"></app-header-back>
    <div class="content scroll-y">
        <article class="w700 m-auto">
            <h2 [innerHTML]="document.title"></h2>
            <div [innerHTML]="document.text"></div>
            <div class="pad10">
                <img [src]="signature" class="w-100" alt="Signature">
            </div>
            <br>
            <div class="info">
                <dd>{{ contact.name }} {{ contact.lastname }}</dd>
                <dd>{{ contact.document_type }} {{ contact.document_number }}</dd>
                <dd>Correo: {{ contact.email }}</dd>
                <dd>Teléfono: {{ contact.phone }}</dd>
                <br>
                <dd>Iniciales Novo Nordisk: <span class="resp">{{ contact.code }}</span></dd>
            </div>
            <div class="pad10">
                <button mat-stroked-button class="btn blue box-center">
                    <span>Enviar copia</span>
                    <i class="fal fa-envelope"></i>
                </button>
            </div>
            <div class="box-center form-group pad10">
                <button mat-flat-button color="primary" class="btn btn-sign" [routerLink]="['/']">
                    <i class="fal fa-chevron-left"></i>
                    <span>VOLVER</span>
                </button>
            </div>
        </article>

    </div>
</main>
<style>
    .main {
        display: flex;
        flex-direction: column;
    }

    article{
        padding-left:1rem;
        padding-right:1rem;
    }

    dd{
        color: #555353;
        margin-bottom:.5rem;
        font-size: .9rem;
    }

    .btn i{
        margin-right:.5rem;
    }

    .btn-sign{
        min-width: 350px;
    }

    mat-checkbox{
        font-size: .9rem;
    }

    .resp{
        border:1px solid #0b1966;
        padding:.2rem .75rem;
        border-radius: 2rem;
        color:#0b1966;
    }
</style>
