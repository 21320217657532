import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'app-search-mobile',
  templateUrl: './search-mobile.component.html'
})
export class SearchMobileComponent implements OnInit, OnDestroy {

  searchWord: string;
  public searchSubject = new Subject<string>();
  private sub: any;

  @Output() searchChange = new EventEmitter<string>();

  @Input()
  get search(): string {
    return this.searchWord;
  }

  set search(value: string) {
    this.searchWord = value;
    this.searchChange.emit(this.searchWord);
  }

  @Output() doSearch = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    this.sub = this
      .searchSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe((value: string) => {
        this.doSearch.emit(value);
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  clear() {
    this.searchWord = '';
    this.doSearch.emit('');
  }
}
