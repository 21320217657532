<section class="back-gris box-left extended" [class.desktop]="desktop">
    <div class="box-left double-spaced">
        <img height="30" class="logo m-auto" src="assets/icons/logo.svg" style="margin-left: 10px;">
        <h3>Habeas Data</h3>
    </div>
    <div>
        <button mat-stroked-button color="primary" class="box-center" (click)="signOut()">
            <i class="fal fa-sign-out"></i>
            <span>Cerrar Sesión</span>
        </button>
    </div>
</section>
<style>
    section {
        height: 50px;
        padding-right: 1rem;
    }

    h3 {
        margin: 0;
        width: 8rem;
    }

    button{
        font-size: .8rem;
    }

    button i{
        margin-right: .5rem;
    }

    button span{
        position: relative;
        top:-2px;
    }

    @media(max-width: 750px){
        h3{
            font-size: 1rem;
        }
    }

    section.desktop{
        background-color: #fff;
        box-shadow: 0 3px 6px rgba(0,0,0,0.1);
        position: sticky;
        top:0;
        width: 100%;
        padding-left: 1rem;
    }
</style>
