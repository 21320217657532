import {Component, Input, OnInit} from '@angular/core';
import {ApiService, IContactList} from '../../services/api.service';
import {Router} from '@angular/router';
import {ContactService} from '../../services/contact.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit {

  @Input() item: IContactList;
  active: boolean;

  constructor(private router: Router,
              private api: ApiService,
              private contactService: ContactService,
              private snack: MatSnackBar) {
    this.active = false;
  }

  ngOnInit() {
  }

  showDocument() {
    this.api.getContactById(this.item.id).subscribe({
      next: (contact: any) => {
        this.contactService.saveLocalContact(contact);
        this.router.navigate(['/document']);
      },
      error: () => {
        this.error();
      }
    });
  }

  editContact() {
    this.api.getContactById(this.item.id).subscribe({
      next: (contact: any) => {
        this.contactService.saveLocalContact(contact);
        this.router.navigate(['/create']);
      },
      error: () => {
        this.error();
      }
    });
  }

  sendEmail(): void {
    this.api.sendEmail(this.item.id).subscribe({
      next: () => {
        this.snack.open('Se ha enviado el correo de forma exitosa', 'Aceptar', {
          duration: 3000
        });
      },
      error: () => {
        this.snack.open('Se ha presentado un error al enviar el correo', 'Aceptar', {
          duration: 3000
        });
      }
    });
  }

  error(): void {
    this.snack.open('Se presento un error consultando el detalle del documento', 'Aceptar', {
      duration: 3000
    });
  }
}
