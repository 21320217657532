<section>
  <mat-form-field class="w-100">
    <mat-label>Ingrese el ID, número de cédula, nombre o correo del contacto</mat-label>
    <mat-icon matPrefix class="icon">search</mat-icon>
    <input id="search" matInput
           [(ngModel)]="searchWord"
           (ngModelChange)="searchChange.emit(searchWord)"
           (input)="searchSubject.next(searchWord)">
    <mat-icon matSuffix class="icon" (click)="clear()">close</mat-icon>
  </mat-form-field>
</section>
<style>
  section{
    padding:.75rem 1rem;
    padding-bottom: 0;
  }

  .icon{
    margin-right: .5rem;
    opacity: .5;
    position: relative;
    top:5px;
  }
</style>
