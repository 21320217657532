import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {HttpBackend, HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ContactService} from './contact.service';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalSignGuard implements CanActivate {
  private http: HttpClient;

  constructor(handler: HttpBackend, private cs: ContactService, private apiService: ApiService,
              private router: Router) {
    this.http = new HttpClient(handler);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const ev = route.paramMap.get('event');
    if (!ev) {
      this.apiService.errorMessages = ['Lo sentimos, el enlace ingresado no es válido.'];
      this.router.navigate(['/error']);
    }
    const params = new HttpParams().append('ev', ev);
    return this.http.get(`${environment.apiRoot}/api/sign-event`, {
      params
    }).pipe(map((response: any) => {
      this.apiService.version = response.habeas;
      this.apiService.contactTypes = response.contact_types;
      this.apiService.documents = response.document_types;
      return true;
    }), catchError((error: HttpErrorResponse) => {
      this.apiService.errorMessages = error.error.errors;
      this.router.navigate(['/error']);
      return of(false);
    }));
  }
}
