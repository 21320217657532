import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, Subject} from 'rxjs';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {map, timeout} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BackofficeService {
  private filters: any;
  private contact: Subject<any>;
  public loading: boolean;
  private search: string;
  private page: number;

  constructor(private http: HttpClient, private san: DomSanitizer) {
    this.filters = {};
    this.contact = new Subject<any>();
    this.loading = false;
  }

  getContacts() {
    const obj: { [key: string]: string } = {};
    for (const key of Object.keys(this.filters)) {
      if (this.filters[key]) {
        obj[key] = this.filters[key];
      }
    }
    if (this.search) {
      obj.search = this.search;
    }
    if (this.page) {
      obj.page = `${this.page}`;
    }

    this.http.get(`${environment.apiRoot}/backoffice/contacts`, {
      params: new HttpParams({
        fromObject: obj
      })
    }).subscribe((data: any) => {
      this.loading = false;
      this.contact.next(data);
    });
  }

  public setFilters(filters?: { [key: string]: any }): void {
    this.filters = filters || {};
    this.loading = true;
    this.getContacts();
  }

  get contacts(): Observable<any> {
    return this.contact.asObservable();
  }

  public download(filter: any): Observable<SafeUrl> {
    const obj: { [key: string]: string } = {};
    for (const key in filter) {
      if (filter[key]) {
        obj[key] = filter[key];
      }
    }
    if (this.search) {
      obj.search = this.search;
    }
    return this.http.get(`${environment.apiRoot}/backoffice/contacts/download`, {
      params: new HttpParams({
        fromObject: obj
      }),
      responseType: 'blob'
    }).pipe(map((xlsx: any) => {
      return this.san.bypassSecurityTrustUrl(URL.createObjectURL(xlsx));
    }), timeout(60 * 5 * 1000));
  }

  public setSearch(value: string) {
    this.search = value;
    this.loading = true;
    this.getContacts();
  }

  public nextPage(page: number): void {
    this.page = page;
    this.loading = true;
    this.getContacts();
  }

  public approveContacts(contactList: Array<any>) {
    const body = {contacts: contactList};
    return this.http.post(`${environment.apiRoot}/backoffice/contacts/approve`, body);
  }

  public rejectContacts(contactList: Array<any>) {
    const body = {contacts: contactList};
    return this.http.post(`${environment.apiRoot}/backoffice/contacts/reject`, body);
  }

  public getPdf(url) {
    return this.http.get(url, {responseType: 'blob'});
  }
}
