import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-progress',
    templateUrl: './progress.component.html'
})
export class ProgressComponent implements OnInit {

    @Input() count: any;

    constructor() {
    }

    ngOnInit() {
    }

    percent(data) {
        return data / this.count.total;
    }
}
