<main class="main">
  <app-header-back title="Firmar documento" back="create" [hidden]="expandedView"></app-header-back>
  <div class="content scroll-y" [class.disable-scroll]="expandedView">

    <article class="w700 m-auto">
      <div>
        <h2 [innerHTML]="document?.title"></h2>
        <div class="habeas-content" [innerHTML]="document?.text"></div>
      </div>
      <div class="pad10 clean-field" style="margin-top: 1rem">
        <div class="box-left extended">
          <button mat-stroked-button class="btn blue box-center" (click)="clearSignature()">
            <span>Limpiar Campo</span>
            <i class="fal fa-redo"></i>
          </button>
          <div class="box-center screen-btn mobile" (click)="toggleExtended(true)">
            <i class="fal fa-expand-arrows"></i>
          </div>
        </div>
      </div>
      <form [formGroup]="formHB" (ngSubmit)="saveSignature()">
        <div class="signature-wrap" [class.active]="expandedView">
          <div (mousedown)="toggleExtended(true)" class="canvas-over mobile" *ngIf="!expandedView">
            <img *ngIf="signature" [src]="signature" style="max-height: 150px">
            <p *ngIf="!signature" style="padding: 1rem"> Click para firmar </p>
          </div>
          <div class="signature">
            <app-signature formControlName="signature" class="desktop" [class.show]="expandedView"
                           #appSignature></app-signature>
            <div *ngIf="expandedView" class="box-center double-spaced expanded-buttons">
              <div>
                <div class="box-center screen-btn" (click)="exitExpanded()">
                  <i class="fal fa-times"></i>
                </div>
              </div>
              <div>
                <div class="box-center screen-btn" (click)="clearSignature()">
                  <i class="fal fa-redo"></i>
                </div>
              </div>
              <div>
                <div class="box-center screen-btn" (click)="toggleExtended(false)">
                  <i class="fal fa-check-circle"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="info">
          <dd>{{ contact.name }} {{ contact.lastname }}</dd>
          <dd>{{ contact.document_type }} {{ contact.document_number }}</dd>
          <dd>Correo: {{ contact.email }}</dd>
          <dd>Teléfono: {{ contact.phone }}</dd>
        </div>
        <div class="pad10">
          <button mat-stroked-button class="btn blue box-center" [routerLink]="['/create']">
            <span>Editar datos</span>
            <i class="fal fa-pencil"></i>
          </button>
        </div>
        <div class="pad10">
          <div class="box-center">
            <mat-checkbox formControlName="sendCopy">ENVIAR UNA COPIA AL CORREO DEL CONTACTO</mat-checkbox>
          </div>
        </div>
        <div class="box-center form-group pad10">
          <button type="submit" [disabled]="!formHB.valid" mat-flat-button color="primary" class="btn btn-sign">
            <span>GUARDAR DOCUMENTO</span>
            <i class="fal fa-chevron-right"></i>
          </button>
        </div>
      </form>
    </article>

  </div>
</main>
<style>
  .main {
    display: flex;
    flex-direction: column;
  }

  .signature {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 1rem;
    height: 150px;
    border: 1px dashed #0b1966;
    margin-bottom: 1.5rem;
    position: relative;
    z-index: 3;
  }

  .signature:after {
    content: "";
    display: block;
    height: 1px;
    width: calc(100% - 2rem);
    position: absolute;
    z-index: 3;
    background-color: #fff;
    left: 1rem;
    bottom: 1rem;

  }

  article {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .clean-field {
    padding-top: 0;
  }

  dd {
    color: #555353;
    margin-bottom: .5rem;
    font-size: .9rem;
  }

  .btn i {
    margin-left: .5rem;
  }

  .btn-sign {
    min-width: 350px;
  }

  mat-checkbox {
    font-size: .9rem;
    max-width: 350px;
  }

  .signature-wrap {
    position: relative;
  }

  .canvas-over {
    position: absolute;
    z-index: 4;
    width: 100%;
    height: 100%;
  }

  .signature-wrap.active {
    position: fixed !important;
    top: 0;
    left: 0;
    height: 100vh !important;
    width: 100vw !important;
    margin: 0 !important;
    background-color: #fff;
    z-index: 2;
    padding: 1rem;
  }

  .signature-wrap.active .signature {
    height: calc(100% - 50px) !important;
    width: 100% !important;
    margin: 0 auto;
    max-width: 600px;
  }

  .expanded-buttons {
    margin-top: 2.5rem;
  }

  .screen-btn {
    font-size: 1.5rem;
    padding: .5rem;
    color: #0b1966;
    border-radius: 10px;
  }

  .screen-btn:active {
    background-color: #f4f4f4;

  }

  .expanded-buttons .screen-btn {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .scroll-y.disable-scroll {
    overflow-y: hidden;
    -webkit-overflow-scrolling: unset;
  }

  .desktop {
    display: none;
  }

  .desktop.show {
    display: block;
    width: 100%;
    height: 100%;
  }

  .habeas-content {
    white-space: pre-wrap;
    text-align: justify;
  }

  @media (min-width: 700px) {
    .mobile {
      display: none;
    }

    .desktop {
      display: unset;
    }
  }

</style>
