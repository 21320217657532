<section>
  <div class="gHeadTable">
    <div>ID</div>
    <div class="text-left">Nombre</div>
    <div class="text-left">Apellidos</div>
    <div>Tipo</div>
    <div>Documento</div>
    <div>Correo</div>
    <div>Fecha firma</div>
    <div>Estado</div>
    <div>PDF</div>
  </div>

  <div class="gContact" *ngFor="let c of contacts">
    <div class="text-center">{{ c.id }}</div>
    <div class="text-left">{{ c.name }}</div>
    <div class="text-left">{{ c.lastname }}</div>
    <div class="text-center">{{ c.document_type }} </div>
    <div class="text-center">{{ c.document_number }}</div>
    <div class="text-center">{{ c.email }}</div>
    <div class="text-center">{{ getDate(c.last_signature_date) | date:'dd/MM/yyyy' }}</div>
    <div class="text-center box-center">
      <app-status [status]="c.signature_status"></app-status>
    </div>
    <div class="text-center">
      <div (click)="getPdf(c)"><i class="fa fa-cloud-download"></i></div>
    </div>
  </div>

  <mat-paginator [length]="total"
                 [pageSize]="50"
                 [pageIndex]="page"
                 (page)="next($event)">
  </mat-paginator>

  <a #anchor hidden [href]="safeLink" [download]="name" target="_blank"></a>
</section>
<style>
  section {
    padding: 1rem;
  }

  .gHeadTable {
    display: grid;
    grid-template-columns: 4% 18% 18% 5% 10% 20% 10% 10% 5%;
    height: 50px;
    align-items: center;
    background: linear-gradient(to bottom, #0067A9 -10%, #0b1966 100%);
    font-size: .9rem;
    color: #fff;
    text-align: center;
    border-radius: 5px 5px 0 0;
    position: sticky;
    top: 50px;
  }

  .gHeadTable > div {
    padding: .5rem;
  }

  .gContact {
    display: grid;
    grid-template-columns: 4% 18% 18% 5% 10% 20% 10% 10% 5%;
    cursor: pointer;
    align-items: center;
    border-top: 1px solid #eaeaea;
  }

  .gContact > div {
    padding: 1.25rem .5rem;
    font-size: .85rem;
    color: #6D6E71;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>
