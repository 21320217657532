<h1 mat-dialog-title *ngIf="data.title">{{ data.title }}</h1>
<div mat-dialog-content>
  <p [innerHTML]="data.content"></p>
</div>
<div mat-dialog-actions class="box-center">
  <button mat-flat-button class="btn w-100" color="primary" (click)="click(true)">
    {{ data.ok }}
  </button>
  <button mat-flat-button class="btn w-100" color="secondary" (click)="click(false)">
    {{ data.cancel }}
  </button>
</div>
