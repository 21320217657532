import { BrowserModule, HammerModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {MaterialModule} from '../modules/material.module';
import {LoginComponent} from './views/login/login.component';
import {RecoveryComponent} from './views/login/recovery/recovery.component';
import {MainComponent} from './views/main/main.component';
import {CreateComponent} from './views/create/create.component';
import {SignComponent} from './views/sign/sign.component';
import {HeaderMainComponent} from './partials/header-main/header-main.component';
import {HeaderBackComponent} from './partials/header-back/header-back.component';
import {DocumentComponent} from './views/document/document.component';
import {SearchComponent} from './components/search/search.component';
import {ContactsComponent} from './components/contacts/contacts.component';
import {TableBaseComponent} from './partials/table-base/table-base.component';
import {StatusComponent} from './components/status/status.component';
import {ActionsComponent} from './components/actions/actions.component';
import {ContactComponent} from './components/contact/contact.component';
import {ProgressComponent} from './components/progress/progress.component';
import {SignatureComponent} from './components/signature/signature.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TokenInterceptor} from './services/token.interceptor';
import {ModalsModule} from '../modules/modals.module';
import {UserService} from './services/user.service';
import {GuardService} from './services/guard.service';
import {BackofficeComponent} from './views/backoffice/backoffice.component';
import {FiltrosComponent} from './views/backoffice/filtros/filtros.component';
import {TablaBackofficeComponent} from './views/backoffice/tabla-backoffice/tabla-backoffice.component';
import {AdminResolver} from './services/admin.resolver';
import {SearchMobileComponent} from './components/search-mobile/search-mobile.component';
import {NumericDirective} from './directives/numeric.directive';
import {SignEmailComponent} from './views/sign-email/sign-email.component';
import {SignGuard} from './services/sign.guard';
import {MessageComponent} from './views/message/message.component';
import {ExternalSignGuard} from './services/external-signature.guard';
import {EventCreateComponent} from './views/create-event/event-create.component';
import {TablaNoAprobadosComponent} from './views/backoffice/tabla-no-aprobados/tabla-no-aprobados.component';
import {RecaptchaFormsModule, RecaptchaModule} from 'ng-recaptcha';

export const routes: Routes = [
  {path: '', component: MainComponent, canActivate: [GuardService]},
  {path: 'login', component: LoginComponent},
  {path: 'recovery', component: RecoveryComponent},
  {path: 'create', component: CreateComponent, canActivate: [GuardService]},
  {path: 'sign', component: SignComponent, canActivate: [GuardService]},
  {path: 'document', component: DocumentComponent, canActivate: [GuardService]},
  {path: 'contactos', component: BackofficeComponent, canActivate: [GuardService], resolve: {data: AdminResolver}},
  {path: 'firmar-habeas-data', component: SignEmailComponent, canActivate: [SignGuard]},

  {path: 'evento/:event', component: EventCreateComponent, canActivate: [ExternalSignGuard]},
  {path: 'evento', component: EventCreateComponent},
  {path: 'evento/firmar/:event', component: SignEmailComponent, canActivate: [ExternalSignGuard]},
  {path: 'error', component: MessageComponent},
  {path: 'exito', component: MessageComponent, data: {
    message: 'Se ha guardado la firma exitosamente. Puede cerrar esta página.'
  }},
  {path: '**', redirectTo: '', pathMatch: 'full'}
];

export function initFactory(user: UserService): () => Promise<any> {
  return () => {
    return user.checkToken();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RecoveryComponent,
    MainComponent,
    CreateComponent,
    SignComponent,
    HeaderMainComponent,
    HeaderBackComponent,
    DocumentComponent,
    SearchComponent,
    ContactsComponent,
    TableBaseComponent,
    StatusComponent,
    ActionsComponent,
    ContactComponent,
    ProgressComponent,
    SignatureComponent,
    BackofficeComponent,
    FiltrosComponent,
    TablaBackofficeComponent,
    SearchMobileComponent,
    NumericDirective,
    SignEmailComponent,
    MessageComponent,
    EventCreateComponent,
    TablaNoAprobadosComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    RouterModule,
    MaterialModule,
    HttpClientModule,
    ModalsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HammerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initFactory,
      deps: [UserService],
      multi: true
    },
    GuardService,
    AdminResolver,
    SignGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
