<div style="width: calc(100% + 2rem); height: calc(100% + 2rem); margin: -1rem">
    <canvas width="100" height="100"
            (mousedown)="beginMouseDraw($event)"
            (mouseup)="endDraw()"
            (mousemove)="mouseDraw($event)"

            (panstart)="beginTouchDraw($event)"
            (panmove)="touchDraw($event)"
            (panend)="endDraw()"
            #canvasElement></canvas>
</div>
