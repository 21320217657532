<section [hidden]="hidden" class="back-gris spaced" [class.box-left]="!center" [class.box-center]="center">
  <div [hidden]="noBackButton" class="box box-center" (click)="onAction()">
    <mat-icon class="icon">arrow_back_ios</mat-icon>
  </div>
  <h3 [class.header-pad]="noBackButton">{{ title }}</h3>
</section>
<style>
  section {
    height: 50px;
  }

  h3 {
    margin: 0;
  }

  .box {
    width: 50px;
    height: 50px;
    color: #0b1966;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .icon {
    margin-left: 7px;
  }

  .header-pad {
    margin-left: 25px;
  }

  .box:active {
    background-color: rgba(0, 0, 0, 0.05);
  }

  [hidden] {
    display: none!important;
  }
</style>
