import {NgModule} from '@angular/core';
import {MaterialModule} from './material.module';
import {AcceptComponent} from '../app/components/modals/accept.component';
import {ConfirmComponent} from '../app/components/modals/confirm/confirm.component';

@NgModule({
    imports: [
        MaterialModule
    ],
    declarations: [
        AcceptComponent,
        ConfirmComponent
    ],
    exports: [
        AcceptComponent,
        ConfirmComponent
    ]
})
export class ModalsModule {
}
