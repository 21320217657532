import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {UserService} from './user.service';
import {environment} from '../../environments/environment';

@Injectable()
export class GuardService implements CanActivate {
  constructor(private user: UserService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const valid = !!this.user.TOKEN;
    if (environment.digital) {
      this.router.navigate(['/error']);
      return false;
    }

    const back = route.routeConfig.path.includes('contactos');
    if (!valid) {
      this.router.navigate(['/login']);
    } else if (environment.app) {
      if (back) {
        this.router.navigate(['']);
      }
    } else if (this.user.user.is_staff && !back) {
      this.router.navigate(['/contactos']);
    }
    return !!this.user.TOKEN;
  }


}
