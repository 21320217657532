import {Component, OnInit} from '@angular/core';
import {StatusBar} from '@capacitor/status-bar';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
    StatusBar.hide().then(() => {
      console.log('success');
    }).catch(() => {
    });
  }
}
