import {Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';

@Injectable()
export class AdminResolver implements Resolve<boolean> {
  public reps: Array<any>;
  public business: Array<any>;
  public managers: Array<any>;
  public status: Array<any>;
  public contactTypes: Array<any>;
  public events: Array<any>;

  constructor(private http: HttpClient) {
  }

  resolve(): Observable<boolean> {
    return this.http.get(`${environment.apiRoot}/backoffice/data`).pipe(map((response: any) => {
      this.reps = response.reps || [];
      this.business = response.business || [];
      this.managers = response.managers || [];
      this.status = response.status || [];
      this.contactTypes = response.contact_types || [];
      this.events = response.events || [];
      return true;
    }));
  }
}
