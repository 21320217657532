<div class="progress pad">
    <div class="heading">
        <div>FIRMADOS {{ count.ok }} ({{ percent(count.ok) | percent }})</div>
        <div>
            <div>ANTERIOR {{ count.old }}</div>
            <div class="no-signature">SIN FIRMA {{ count.missing }}</div>
        </div>
    </div>
    <div class="bar">
        <div class="ok" [ngStyle]="{'width': percent(count.ok) | percent}"></div>
        <div class="old" [ngStyle]="{'width': percent(count.old) | percent}"></div>
        <div class="missing" [ngStyle]="{'width': percent(count.missing) | percent}"></div>
    </div>
</div>
<style>
    .heading{
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: .8rem;
        margin-bottom: .5rem;
    }

    .heading>div:first-child{
        color: #27ae60;
    }

    .heading>div:last-child{
        color: #E28228;
    }

    .no-signature {
        color: #e2282e!important;
    }

    .bar{
        width: 100%;
        display: flex;
    }

    .bar>div{
        height:8px;
    }

    .bar>div.ok{
        background-color:#27ae60;
    }

    .bar>div.old{
        background-color:#E28228;
    }

    .bar>div.missing{
        background-color:#e2282e;
    }
</style>
