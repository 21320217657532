import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TablaBackofficeComponent} from '../tabla-backoffice/tabla-backoffice.component';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {ConfirmComponent} from '../../../components/modals/confirm/confirm.component';

@Component({
  selector: 'app-tabla-no-aprobados',
  templateUrl: 'tabla-no-aprobados.component.html'
})
export class TablaNoAprobadosComponent extends TablaBackofficeComponent implements OnInit {
  selectAll = false;
  showActions = false;
  @Output() update: EventEmitter<void> = new EventEmitter<void>();

  selectContacts(ev: MatCheckboxChange) {
    this.showActions = !ev.checked;
    for (const c of this.contacts) {
      c.checked = ev.checked;
    }
    this.checkSelected();
  }

  checkSelected() {
    this.showActions = this.contacts.some(c => c.checked);
    this.selectAll = false;
  }

  approve() {
    const contacts = this.contacts.filter(c => c.checked).map(c => c.id);

    const ref = this.dialog.open(ConfirmComponent, {
      width: '500px',
      data: {
        title: 'Aprobar registros',
        content: `Se aprobarán los registros seleccionados (${contacts.length} registro${contacts.length === 1 ? '' : 's'}).`,
        ok: 'Aprobar',
        cancel: 'Cancelar'
      }
    });

    ref.afterClosed().subscribe(status => {
      if (status) {
        this.api.approveContacts(contacts).subscribe(r => {
          this.snack.open('Contactos aprobados exitósamente', 'Aceptar', {duration: 3000});
          this.update.emit();
        });
      }
    });
  }

  reject() {
    const contacts = this.contacts.filter(c => c.checked).map(c => c.id);

    const ref = this.dialog.open(ConfirmComponent, {
      width: '500px',
      data: {
        title: 'Rechazar registros',
        content: `Se eliminarán los registros seleccionados (${contacts.length} registro${contacts.length === 1 ? '' : 's'}).
         Esta acción no puede deshacerse. ¿Eliminar registros?`,
        ok: 'Eliminar',
        cancel: 'Cancelar'
      }
    });

    ref.afterClosed().subscribe(status => {
      if (status) {
        this.api.rejectContacts(contacts).subscribe(r => {
          this.snack.open('Contactos eliminados', 'Aceptar', {duration: 3000});
          this.update.emit();
        });
      }
    });
  }
}
