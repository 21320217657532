<main>
  <app-header-main></app-header-main>
  <mat-tab-group mat-stretch-tabs (selectedIndexChange)="tabChange($event)">
    <mat-tab label="BUSCAR CONTACTO">
      <div class="tab-panel">
        <div>
          <app-search-mobile (doSearch)="doSearch($event)"></app-search-mobile>
        </div>
        <div class="tabla-main scroll-y" (scroll)="scrollPage($event)" *ngIf="contacts?.length > 0">
          <app-contacts [data]="contacts"></app-contacts>
          <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
        </div>
        <div *ngIf="contacts?.length === 0 && first"
             class="message">
          No se han encontrado contactos asociados al parametro de busqueda.
        </div>
        <div class="box-center boton-area">
          <button class="btn box-center spaced"
                  mat-flat-button color="warn"
                  (click)="createContact()"
                  *ngIf="search">
            <i class="fal fa-plus"></i>
            <span>CREAR NUEVO CONTACTO</span>
          </button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="MIS CONTACTOS">
      <div class="tab-panel">
        <div>
          <app-search-mobile (doSearch)="doSearch($event)"></app-search-mobile>
          <div class="spaced filters">
            <select class="naui-select" [(ngModel)]="filters.order" (change)="applyFilters()">
              <option value="" selected hidden>Ordenar por</option>
              <option value="name">Nombre</option>
              <option value="document_number">Documento</option>
              <option value="last_signature_date">Fecha</option>
              <option value="signature_status">Estado</option>
            </select>
            <select class="naui-select" [(ngModel)]="filters.period" (change)="applyFilters()">
              <option value="" selected hidden>Periodo</option>
              <option [ngValue]="0">Todos</option>
              <option [ngValue]="1">Hoy</option>
              <option [ngValue]="2">Semana actual</option>
              <option [ngValue]="3">Mes actual</option>
              <option [ngValue]="4">Año actual</option>
            </select>
            <select class="naui-select" hidden>
              <option selected hidden>Formato</option>
            </select>
            <button mat-stroked-button color="primary" class="box-center" (click)="clearFilters()">
              <span>Limpiar filtros <i class="fal fa-sync"></i></span>
            </button>
          </div>
        </div>
        <div class="tabla-main scroll-y" (scroll)="scrollPage($event)">
          <app-contacts [data]="contacts"></app-contacts>
          <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
        </div>
        <div *ngIf="count">
          <app-progress [count]="count"></app-progress>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</main>
<style>
  .tab-panel {
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
  }

  .tabla-main {
    flex: 100%;
    padding-bottom: 1rem;
  }

  button.btn {
    font-size: .9rem;
    padding: 0 2rem;
  }

  .boton-area {
    padding: .75rem;
  }

  .filters {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .filters > * {
    margin-bottom: 1rem;
  }

  .message {
    color: #0b1966;
    font-size: 0.8rem;
    margin: 0rem 0.5rem;
    text-align: center;
  }
</style>
