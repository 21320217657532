import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../services/api.service';
import {environment} from '../../../environments/environment';

@Component({
  templateUrl: 'message.component.html'
})
export class MessageComponent implements OnInit {
  message = '';
  constructor(private route: ActivatedRoute, private apiService: ApiService) {}

  ngOnInit() {
    this.message = this.apiService.errorMessages ?
        this.apiService.errorMessages[0] :
        this.route.snapshot.data.message;

    if (!this.message) {
      this.message = 'Lo sentimos, este enlace no es válido';
    }
  }

  get version() {
    return environment.version;
  };
}
