import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService, IContactDetails } from '../../services/api.service';
import { ContactService } from '../../services/contact.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AcceptComponent } from '../../components/modals/accept.component';
import { first } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { SignatureComponent } from '../../components/signature/signature.component';
import { ConfirmComponent } from '../../components/modals/confirm/confirm.component';
import { environment } from '../../../environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  templateUrl: 'sign-email.component.html'
})
export class SignEmailComponent implements OnInit {

  document: any;
  contact: IContactDetails;
  submitEmail = false;
  expandedView = false;
  event = null;

  formHB = new FormGroup({
    signature: new FormControl('', [Validators.required, Validators.minLength(5000)]),
    sendCopy: new FormControl(false)
  });

  @ViewChild('appSignature', { static: false }) signatureComp: SignatureComponent;

  constructor(protected api: ApiService,
              private cs: ContactService,
              private dialog: MatDialog,
              private router: Router, private route: ActivatedRoute) {
    this.document = api.version;
  }

  ngOnInit() {
    this.contact = this.cs.loadLocalContact();
    this.event = this.route.snapshot.paramMap.get('event');
    if (!this.contact && this.event) {
      this.goBack();
    }
  }

  clearSignature() {
    this.formHB.get('signature').setValue('');
    // this.canvasData = '';
    this.signatureComp.clear();
  }

  exitExpanded() {
    this.signatureComp.clear();
    this.expandedView = false;
    this.signatureComp.toggleExtended(this.expandedView);
  }

  async checkFormValid() {
    const dialog = this.dialog.open(ConfirmComponent, {
      width: '550px',
      data: {
        title: '¿Enviar formulario?',
        content: 'Verifique que sus datos se encuentren diligenciados correctamente',
        cancel: 'No',
        ok: 'Si'
      }
    });

    dialog.afterClosed()
      .pipe(first())
      .subscribe((status: boolean) => {
        if (status) {
          this.saveSignature();
        }
      });
  }

  private saveSignature() {
    const image = this.formHB.get('signature').value;
    const sendCopy = this.formHB.get('sendCopy').value;
    let sub = this.api.saveSignatureEmail(image, sendCopy);

    if (this.event) {
      sub = this.api.saveSignatureEmail(image, sendCopy, this.event, this.contact);
    }

    sub.subscribe({
      next: (response: any) => {
        this.router.navigate(['/exito']);
      },
      error: (error: HttpErrorResponse) => {
        let message = '';
        if (error.status >= 400) {
          if (error.error.message) {
            message = error.error.message;
          } else {
            message = `Ocurrió un error en la solicitud. [Código de error: ${ error.status }]`;
          }
        } else if (!error.status) {
          message = 'Ocurrió un error de red. Este error puede ser causado por un proxy bloqueando la solicitud,' +
            ' o por un bajo nivel de señal. Pruebe conectándose a una red diferente e inténtelo nuevamente.';
        }
        this.showError(message);
      }
    });
  }

  private showError(message) {
    this.dialog.open(AcceptComponent, {
      width: '500px',
      data: {
        title: 'Error',
        content: message,
        button: 'Aceptar'
      }
    });
  }

  get signature(): string | undefined {
    const sign = this.formHB.get('signature').value;
    return sign !== '' ? sign : undefined;
  }

  toggleExtended(status) {
    this.expandedView = status;
    this.signatureComp.toggleExtended(status);
  }

  get version() {
    return environment.version;
  }

  goBack() {
    this.router.navigate(['/evento', this.event]);
  }
}
