import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-status',
    templateUrl: './status.component.html'
})
export class StatusComponent implements OnInit {

    @Input() status: number;

    constructor() {
    }

    ngOnInit() {
    }

}
