import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'app-header-main',
    templateUrl: './header-main.component.html'
})
export class HeaderMainComponent implements OnInit {

    @Input() desktop: boolean;

    constructor(protected router: Router, private userService: UserService) {
    }

    ngOnInit() {
    }

    signOut() {
        this.userService.logout();
        this.router.navigate(['/login']);
    }

}
