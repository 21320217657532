import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {BackofficeService} from '../../services/backoffice.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit, OnDestroy {

  searchWord: string;
  public searchSubject = new Subject<string>();
  private sub: any;

  @Output() searchChange = new EventEmitter<string>();

  @Input()
  get search(): string {
    return this.searchWord;
  }

  set search(value: string) {
    this.searchWord = value;
    this.searchChange.emit(this.searchWord);
  }

  @Output() doSearch = new EventEmitter();

  constructor(private api: BackofficeService) {
  }

  ngOnInit() {
    this.sub = this
      .searchSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(() => {
        this.api.setSearch(this.searchWord);
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  clear() {
    this.searchWord = '';
    this.api.setSearch(this.searchWord);
  }
}
