import {Component, Input, OnInit} from '@angular/core';
import {IContactList} from '../../services/api.service';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html'
})
export class ContactsComponent implements OnInit {

    @Input() data: Array<IContactList>;

    constructor() {
    }

    ngOnInit() {
    }

}
