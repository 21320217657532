<div class="box-center">
    <div class="icon" (click)="editContact(status!==3)">
        <img *ngIf="status!==3" src="assets/icons/lapiz-on.svg">
        <img *ngIf="status===3" src="assets/icons/lapiz-off.svg">
    </div>
    <div class="icon" (click)="showDocument(status===3)">
        <img *ngIf="status===3" src="assets/icons/ojo-on.svg">
        <img *ngIf="status!==3" src="assets/icons/ojo-off.svg">
    </div>

    <div class="icon">
        <img *ngIf="status!==3" src="assets/icons/mensaje.svg" (click)="sendEmail()">
        <img *ngIf="status===3" src="assets/icons/mensaje-off.svg">
    </div>
</div>
<style>
    .box-center {
        justify-content: space-around;
        width: 90px;
    }

    img {
        height: 20px;
    }

    .icon {
        width: 35px;
        height: 35px;
        justify-content: center;
        align-items: center;
        display: flex;
        border: 1px solid #eaeaea;
        border-radius: 50%;
        transition: .2s;
    }

    .icon:active {
        background: rgba(0, 0, 0, 0.075);
    }
</style>
