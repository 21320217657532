import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
	error: string;
	public form: FormGroup;
	public loading: boolean;

	constructor(protected router: Router,
				private us: UserService,
				private fb: UntypedFormBuilder,
				private snack: MatSnackBar) {
	}

	ngOnInit() {
		if (environment.digital) {
			this.router.navigate(['/error']);
		}
		this.form = this.fb.group({
			username: [environment.auth ? environment.auth.username : '', [
			Validators.required,
			Validators.minLength(3),
			Validators.pattern(/^[A-Za-z]+$/)
			]],
			password: [environment.auth ? environment.auth.password : '', Validators.required]
		});
	}

	async signIn() {
		if (this.form.valid) {
			this.loading = true;

			let header = new Headers();
			header.append("Content-Type", "application/json");

			this.form.value.version = "4.2.0";

			let requestOptions = {
				method: 'POST',
				headers: header,
				body: JSON.stringify(this.form.value),
			};
			let errorServer = "";
			const response = await fetch(`${environment.apiRoot}/auth`, requestOptions).catch((error)=> errorServer = error);

			
			if(errorServer == ""){
				const result = await response.json();

				if(this.form.value.version == "4.1.0" && result.detail == "No active account found with the given credentials"){
					this.loading = false;
					this.snack.open(
						"No se ha podido establecer conexión con la red. Verifique su conexión y vuelva a intentarlo.",
						'Aceptar',
						{duration: 3000}
					);
				} else if (this.form.value.version == "4.1.0"){
					let resultLogin = result;

					this.us.login(resultLogin)
						
					this.loading = false;
					if (this.us.user.is_staff && !environment.app) {
						this.router.navigate(['/contactos']);
					} else {
						this.router.navigate(['/']);
					}
				} 
				
				if(this.form.value.version == "4.2.0" && result.error == "null") {
					let resultLogin = result.data;
					this.us.login(resultLogin)
						
					this.loading = false;
					if (this.us.user.is_staff && !environment.app) {
						this.router.navigate(['/contactos']);
					} else {
						this.router.navigate(['/']);
					}
				} else if(this.form.value.version == "4.2.0"){
					this.loading = false;
					this.snack.open(
						result.error,
						'Aceptar',
						{duration: 3000}
					);
				} 
			} else {
				this.loading = false;
				this.snack.open("Error en el servidor", "Aceptar", {duration: 3000});
			}
		}
	}

	get version(): string {
		return environment.version;
	}

	get appName(): string {
		return environment.appName;
	}

	private getLoginError(result) {
		this.loading = false;
		this.snack.open(result, "Aceptar", {duration: 3000});
	}

	private getLoginServerError(error) {
		this.loading = false;
		this.snack.open(error, "Aceptar", {duration: 3000});
	}
}