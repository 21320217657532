import {environment} from './environment';

export interface IEnvironment {
  production: boolean;
  apiRoot: string;
  appName: string
  auth?: {
    username: string,
    password: string
  };
  reCaptcha?: {
    siteKey: string,
  };
  version: string;
  app: boolean;
  digital?: boolean;
}

export const APP_VERSION = '4.2.0';