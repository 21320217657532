import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdminResolver} from '../../../services/admin.resolver';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {BackofficeService} from '../../../services/backoffice.service';

@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html'
})
export class FiltrosComponent implements OnInit {

  @Input() approved: boolean;
  @Output() times = new EventEmitter<boolean>();
  public form: UntypedFormGroup;

  constructor(public data: AdminResolver,
              private fb: UntypedFormBuilder,
              private api: BackofficeService) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      rep: undefined,
      business: undefined,
      manager: undefined,
      period: undefined,
      status: undefined,
      contactType: undefined,
      contactSubType: undefined,
      event: undefined
    });
    this.form.valueChanges.subscribe((value: any) => {
      this.api.setFilters(this.value);
    });
    this.form.controls.contactType.valueChanges.subscribe((value: any) => {
      this.form.patchValue({
        contactSubType: null
      }, {emitEvent: false});
    });
  }

  cerrar() {
    this.times.emit(true);
  }

  get subTypes(): Array<any> {
    const value = this.form ? this.form.value : undefined;
    return value.contactType ? value.contactType.subtypes : [];
  }

  public clear(): void {
    this.form.reset();
  }

  get value(): any {
    const value = this.form.value;
    return {
      approved: this.approved ? 'True' : 'False',
      rep: value.rep ? value.rep.initials : undefined,
      business: value.business ? value.business.id : undefined,
      manager: value.manager ? value.manager.initials : undefined,
      period: value.period ? value.period : undefined,
      status: value.status ? value.status.id : undefined,
      contactType: value.contactType ? value.contactType.id : undefined,
      contactSubType: value.contactSubType ? value.contactSubType.id : undefined,
      event: value.event || undefined
    };
  }
}
