import {Injectable} from '@angular/core';
import Contacts from '../data/dummy.json';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  contacts: Array<IContactList>;

  public version: any;
  public documents: Array<any>;
  public contactTypes: Array<any>;
  public errorMessages: Array<string>;
  signToken = '';

  constructor(private http: HttpClient) {
    this.contacts = Contacts;
  }

  getContacts(search?: string, page?: number): Observable<any> {
    const paramData: any = {};
    if (search) {
      paramData.search = search;
    }
    if (page) {
      paramData.page = page;
    }
    const params = new HttpParams({fromObject: paramData});

    return this.http
      .get(`${environment.apiRoot}/api/contacts`, {params});
  }

  getContactById(id: number) {
    return this.http.get(`${environment.apiRoot}/api/contacts/${id}`);
  }

  getUserContacts(rep: string, search?: string, page?: number, filters?: any): Observable<any> {
    const query: any = {rep};
    if (search) {
      query.search = search;
    }
    if (page) {
      query.page = page;
    }
    if (filters) {
      for (const key of Object.keys(filters)) {
        if (!!filters[key]) {
          query[key] = filters[key];
        }
      }
    }

    const params = new HttpParams({fromObject: query});
    return this.http.get(`${environment.apiRoot}/api/contacts`, {params});
  }

  getUserCount(rep: string) {
    return this.http.get(`${environment.apiRoot}/api/count/${rep}`);
  }

  saveContact(contact: IContactDetails, signature: string, email: boolean) {
    const body = JSON.parse(JSON.stringify(contact));
    if (signature !== '') {
      body.base64_signature = signature;
      body.sign_timestamp = new Date().getTime();
      body.send_email_to_contact = email;
      body.habeas_data_version_id = this.version.id;
    }
    return this.http.post(`${environment.apiRoot}/api/contacts`, body);
  }

  saveSignatureEmail(signature: string, submitEmail: boolean, event?: string, contact?: any) {
    const body: any = {skip: true};
    if (signature !== '') {
      body.base64_signature = signature;
      body.sign_timestamp = new Date().getTime();
      body.send_email_to_contact = submitEmail;
      body.habeas_data_version_id = this.version.id;
      body.event = event;
    }

    if (event) {
      for (const key of Object.keys(contact)) {
        body[key] = contact[key];
      }
      return this.http.post(`${environment.apiRoot}/api/sign-event`, body);
    }

    return this.http.post(`${environment.apiRoot}/api/sign-by-email`, body, {
      headers: {
        Authorization: `Bearer ${this.signToken}`
      }
    });
  }

  recoverPassword(initials: string) {
    const formData = new FormData();
    formData.append('username', initials);
    return this.http.post(`${environment.apiRoot}/api/reset-password`, formData);
  }

  sendEmail(contactId: number): Observable<any> {
    return this.http.get(`${environment.apiRoot}/api/send-email/${contactId}`);
  }

  sendSignEmail(id: string | number): Observable<any> {
    return this.http.get(`${environment.apiRoot}/api/send-email-to-sign/${id}`);
  }

  checkDocument(docType: string, docNumber: string): Observable<any> {
    return this.http.get(`${environment.apiRoot}/api/check-document`, {
      params: new HttpParams({
        fromObject: {
          doc_type: docType, doc_number: docNumber
        }
      })
    });
  }

  verifyReCaptcha(token): Observable<any> {
    const fd = new FormData();
    fd.append('token', token);
    return this.http.post(`${environment.apiRoot}/api/check-recaptcha`, fd);
  }
}

export interface IContactList {
  id: number;
  fullname: string;
  document_type: string;
  document_number: string;
  signature_status: number;
  phone?: string;
  address?: string;
  email?: string;
  signature_date?: string;
}

export interface IContactDetails {
  id: string;
  name: string;
  lastname: string;
  document_type: string;
  document_number: string;
  email: string;
  phone: string;
  address: string;
  contact_type: any;
  contact_subtype?: any;
  signature_date: number;
  code?: string;
  signature?: string;
}
