import {Injectable} from '@angular/core';
import {IContactDetails} from './api.service';

@Injectable({providedIn: 'root'})
export class ContactService {
  private readonly CONTACT_KEY = 'contactData';

  constructor() {
  }

  saveLocalContact(data: IContactDetails) {
    const stringData = JSON.stringify(data);
    localStorage.setItem(this.CONTACT_KEY, stringData);
  }

  loadLocalContact(): IContactDetails {
    try {
      return JSON.parse(localStorage.getItem(this.CONTACT_KEY));
    } catch (e) {
      return undefined;
    }
  }

  clear(): void {
    console.log('cleared');
    localStorage.removeItem(this.CONTACT_KEY);
  }
}
