import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {BackofficeService} from '../../../services/backoffice.service';
import {environment} from '../../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-tabla-backoffice',
  templateUrl: './tabla-backoffice.component.html'
})
export class TablaBackofficeComponent implements OnInit {

  @Input() contacts: any[];
  @Input() public page: number;
  @Input() public total: number;
  @ViewChild('anchor', {static: true}) anchorElement: ElementRef;
  link: string;
  name: string;

  constructor(protected paginator: MatPaginatorIntl, protected api: BackofficeService, private sanitizer: DomSanitizer,
              protected snack: MatSnackBar, protected dialog: MatDialog) {
    this.paginator.itemsPerPageLabel = 'Contactos por página';
    this.paginator.nextPageLabel = 'Siguiente';
    this.paginator.previousPageLabel = 'Anterior';
    this.paginator.getRangeLabel = (page: number, pageSize: number, length: number) => {
      const next = (page + 1) * pageSize;
      return `${(page * pageSize) + 1} - ${next <= length ? next : length} de ${length}`;
    };
  }

  ngOnInit() {
  }

  getDate(dtString: string) {
    return new Date(dtString);
  }

  public next(e: PageEvent): void {
    this.api.nextPage(e.pageIndex + 1);
  }

  get safeLink() {
    return this.sanitizer.bypassSecurityTrustUrl(this.link);
  }

  getPdf(c: any) {
    this.api.getPdf(`${environment.apiRoot}/backoffice/get-pdf/${c.id}`).subscribe((data) => {
      if (!data.size) {
        this.snack.open(
            'Archivo no encontrado.',
            'Aceptar',
            {duration: 3000}
        );
        return;
      }
      const blob = new Blob([data], {type: 'application/pdf'});
      this.link = URL.createObjectURL(blob);
      this.name = `${c.document_number}.pdf`;
      setTimeout(() => {
        this.anchorElement.nativeElement.click();
      }, 50);
    });
  }
}
