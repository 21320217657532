<app-table-base>
    <ng-container *ngFor="let item of data">
        <app-contact [item]="item"></app-contact>
    </ng-container>
</app-table-base>
<style>
    .info {
        padding: 1rem;
        background-color: #f0f1f4;
    }
</style>

