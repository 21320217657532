<main class="main scroll-y">
  <div>
    <app-header-back class="header" title="Tratamiento de datos personales"
                     [center]="true" [noBackButton]="true" (action)="cs.clear()">
    </app-header-back>
  </div>
  <div class="content box-center" *ngIf="form">
    <img class="logo m-auto" src="assets/icons/logo-novo-nordisk.svg">
    <p class="legend">
      Por favor ingrese sus datos personales para continuar con
      la autorización para el tratamiento de datos personales a
      Novo Nordisk Colombia S.A.S.
    </p>
    <form [formGroup]="form" class="form" (ngSubmit)="goToSignature()">

      <mat-form-field appearance="standard" class="w-100">
        <mat-label>Nombre</mat-label>
        <input matInput
               placeholder="Nombre"
               formControlName="name">
      </mat-form-field>
      <mat-form-field appearance="standard" class="w-100">
        <mat-label>Apellido</mat-label>
        <input matInput
               placeholder="Apellido"
               formControlName="lastname">
      </mat-form-field>
      <div class="document box-left double-spaced">
        <div>
          <mat-form-field>
            <mat-label>Tipo de documento</mat-label>
            <select matNativeControl
                    formControlName="document_type">
              <option [ngValue]="undefined">Seleccione</option>
              <option [value]="item.value" *ngFor="let item of documents">{{ item.label }}</option>
            </select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="standard" class="w-100">
            <mat-label>Documento</mat-label>
            <input matInput appNumeric type="text"
                   placeholder="Documento"
                   formControlName="document_number">
          </mat-form-field>
        </div>
      </div>
      <mat-form-field appearance="standard" class="w-100">
        <mat-label>Correo</mat-label>
        <input matInput
               placeholder="Correo"
               formControlName="email">
      </mat-form-field>
      <mat-form-field appearance="standard" class="w-100">
        <mat-label>Teléfono</mat-label>
        <input matInput appNumeric
               type="text"
               placeholder="Teléfono"
               formControlName="phone">
      </mat-form-field>
      <mat-form-field appearance="standard" class="w-100">
        <mat-label>Dirección</mat-label>
        <input matInput
               placeholder="Dirección"
               formControlName="address">
        <mat-icon matSuffix (click)="showAddressMessage()">help_outline</mat-icon>
        <mat-hint align="start">Ingrese la dirección principal del contacto</mat-hint>
      </mat-form-field>
      <div class="contact-type box-left double-spaced">
        <div>
          <mat-form-field>
            <mat-label>Tipo de contacto</mat-label>
            <select matNativeControl
                    [compareWith]="compareItem"
                    formControlName="contact_type">
              <option [ngValue]="undefined" >Seleccione</option>
              <option [ngValue]="{id: item.id, name: item.name}" *ngFor="let item of contactTypes">
                {{ item?.name }}
              </option>
            </select>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field *ngIf="subTypes?.length > 0 && contactType">
            <mat-label>{{ subTypeLabel }}</mat-label>
            <select matNativeControl
                    [compareWith]="compareItem"
                    formControlName="contact_subtype">
              <option [ngValue]="undefined">Seleccione</option>
              <option [ngValue]="{id: item.id, name: item.name}" *ngFor="let item of subTypes">
                {{ item?.name }}
              </option>
            </select>
          </mat-form-field>
        </div>
      </div>
      <div class="box-center pad-top-1">
        <re-captcha (resolved)="resolved($event)"
                    [siteKey]="siteKey"></re-captcha>
      </div>
      <div class="form-group box-center main-action">
        <button mat-flat-button color="primary" class="btn w-100" type="submit" [disabled]="!success">
          <span>CONTINUAR A FIRMA</span>
          <i class="fal fa-angle-right"></i>
        </button>
      </div>
    </form>
  </div>
</main>
<style>
  .main {
    display: flex;
    flex-direction: column;
  }

  .content {
    display: block;
  }

  .form {
    width: 500px;
    padding: 0 2rem;
  }

  p.legend {
    width: 500px;
    padding: 0 2rem;
    text-align: center;
  }

  .document {
    display: flex;
  }

  .document > div:first-child {
    flex: 35%;
  }

  .contact-type {
    margin-top: 3rem;
  }

  .form-group {
    margin-top: 2rem;
  }

  .header {
    min-height: 50px;
    flex: max-content;
  }

  .logo {
    padding: 1rem;
  }

  .scroll-y::-webkit-scrollbar {
    display: block;
    -webkit-appearance: none;
    width: 7px;
  }

  .scroll-y::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  @media (max-width: 750px) {
    .content {
      display: block;
      align-self: flex-start;
    }

    .form {
      width: 100vw;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 20px;
    }

    .document, .contact-type {
      display: block
    }

    p.legend {
      width: 100vw;
    }
  }

</style>
