import {Component, OnInit} from '@angular/core';
import {ApiService, IContactDetails, IContactList} from '../../services/api.service';
import {ContactService} from '../../services/contact.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html'
})
export class DocumentComponent implements OnInit {

  document: any;
  contact: IContactDetails;
  public signature: SafeUrl;

  constructor(protected api: ApiService,
              public contactService: ContactService,
              public dom: DomSanitizer) {

    this.document = api.version;
    this.contact = contactService.loadLocalContact();
    this.signature = this.dom.bypassSecurityTrustUrl(this.contact.signature);
  }

  ngOnInit() {
  }

}
