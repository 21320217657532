import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import * as Hammer from 'hammerjs';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SignComponent } from '../../views/sign/sign.component';

@Component({
  selector: 'app-signature',
  templateUrl: 'signature.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SignatureComponent),
      multi: true
    }
  ]
})
export class SignatureComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  @ViewChild('canvasElement', { static: true }) canvasArea: ElementRef;
  private canvasContext: CanvasRenderingContext2D;
  private position = { x: 0, y: 0 };
  private drawing = false;
  disabled = false;

  private signData: Array<StrokePath> = [];

  private resizeSubject = new Subject<void>();
  private rect: any;

  private mc: any;
  private rotated: any;

  value: string | undefined;

  private fn = (e) => {
    e.preventDefault();
  }

  constructor() {
  }

  ngOnInit(): void {
    this.resizeSubject.asObservable()
      .pipe(debounceTime(400))
      .subscribe(() => {
        this.resizeCanvas();
      });
  }

  ngAfterViewInit(): void {
    const el = this.canvasArea.nativeElement;
    const ctx = this.canvasArea.nativeElement.getContext('2d');

    const recognizer = new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 2 });
    const mc = new Hammer.Manager(el);
    mc.add(recognizer);

    mc.on('panstart', e => {
      this.beginTouchDraw(e);
    });
    mc.on('panmove', e => {
      this.touchDraw(e);
    });
    mc.on('panend', () => {
      this.endDraw();
    });

    el.addEventListener('touchstart', this.fn, true);
    el.addEventListener('touchmove', this.fn, true);
    el.addEventListener('touchend', this.fn, true);
    el.addEventListener('touchcancel', this.fn, true);

    this.mc = mc;

    ctx.lineWidth = 2;
    ctx.lineCap = 'round';
    ctx.strokeStyle = '#000000';

    this.canvasContext = ctx;

    this.resizeCanvas();
  }

  resizeCanvas(transformRotate?): void {
    const el = this.canvasArea.nativeElement;
    const rect = el.parentElement.getBoundingClientRect();

    this.rect = rect;

    el.width = rect.width;
    el.height = rect.height;

    this.loadPaths();
  }

  ngOnDestroy(): void {
    const el: HTMLCanvasElement = this.canvasArea.nativeElement;
    el.removeEventListener('touchstart', this.fn, true);
    el.removeEventListener('touchmove', this.fn, true);
    el.removeEventListener('touchend', this.fn, true);
    el.removeEventListener('touchcancel', this.fn, true);

    this.mc.off('panstart');
    this.mc.off('panmove');
    this.mc.off('panend');
    this.mc.remove('pan');
    this.mc.destroy();

    this.resizeSubject.complete();
  }

  beginMouseDraw(e) {
    this.signData.push(new StrokePath(this.rotated));
    this.setPosition({ x: e.clientX, y: e.clientY });
    this.drawing = true;
  }

  beginTouchDraw(e) {
    this.signData.push(new StrokePath(this.rotated));
    this.setPosition({ x: e.center.x, y: e.center.y });
    this.drawing = true;
  }

  endDraw() {
    this.drawing = false;
    this.onTouch();
    this.value = this.imageString;
    this.onChange(this.value);
  }

  touchDraw(e) {
    this.draw({ x: e.center.x, y: e.center.y });
  }

  mouseDraw(e) {
    this.draw({ x: e.clientX, y: e.clientY });
  }

  private draw(c: ICoords, load?: boolean) {
    if (this.drawing || load) {
      const ctx = this.canvasContext;

      ctx.beginPath();
      ctx.moveTo(this.position.x, this.position.y);

      this.setPosition(c, load);

      ctx.lineTo(this.position.x, this.position.y);
      ctx.stroke();
    }
  }

  private setPosition(e: ICoords, load?: boolean) {
    const canvas = this.canvasArea.nativeElement;
    let x = e.x;
    let y = e.y;

    if (!load) {
      const rect = canvas.getBoundingClientRect();
      x = e.x - rect.left;
      y = e.y - rect.top;
      this.lastStroke.coords.push({ x, y });
    }

    this.position.x = x;
    this.position.y = y;
  }

  private loadPaths(): void {
    if (this.signData.length < 1) {
      return;
    }

    for (const data of this.signData) {
      this.setPosition(data.coords[0], true);
      for (const pos of data.coords.slice(1)) {
        this.draw(pos, true);
      }
      this.canvasContext.restore();
    }
  }

  clear() {
    const ctx = this.canvasContext;
    this.signData = [];
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  }

  async getImage(): Promise<string> {
    return new Promise((resolve) => {
      if (!this.signData.length) {
        resolve('');
      }
      resolve(this.canvasArea.nativeElement.toDataURL());
    });
  }

  get imageString(): string {
    if (!this.signData.length) {
      return '';
    } else {
      return this.canvasArea.nativeElement.toDataURL();
    }
  }

  get strokeCount() {
    let sum = 0;
    for (const item of this.signData) {
      sum += item.coords.length;
    }
    return sum;
  }

  async toggleExtended(status: boolean) {
    this.rotated = status;
    if (window.screen && window.screen.orientation) {
      if (this.rotated) {
        window.screen.orientation.lock('landscape');
      } else {
        window.screen.orientation.unlock();
      }
    }

    setTimeout(() => {
      this.resizeCanvas(true);
    }, 100);
  }

  @HostListener('window:resize', [])
  windowResize() {
    this.resizeSubject.next();
  }

  private get lastStroke() {
    return this.signData[this.signData.length - 1];
  }

  // Control Value Accessors
  public onTouch = () => {
  }

  onChange = (_: any) => {
  }

  writeValue(val: any): void {
    if (val) {
      this.value = val;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

}


interface ICoords {
  x: number;
  y: number;
}


class StrokePath {
  public coords: Array<ICoords>;
  public rotated: boolean;

  constructor(rotated = false) {
    this.coords = [];
    this.rotated = rotated;
  }
}
