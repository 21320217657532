import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService, IContactDetails } from '../../services/api.service';
import { SignatureComponent } from '../../components/signature/signature.component';
import { ContactService } from '../../services/contact.service';
import { MatDialog } from '@angular/material/dialog';
import { AcceptComponent } from '../../components/modals/accept.component';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-sign',
  templateUrl: './sign.component.html'
})
export class SignComponent implements OnInit {

  document: any;
  contact: IContactDetails;
  expandedView = false;

  formHB = new FormGroup({
    signature: new FormControl('', [Validators.required, Validators.minLength(5000)]),
    sendCopy: new FormControl(false)
  });

  @ViewChild('appSignature', { static: false }) signatureComp: SignatureComponent;

  constructor(protected api: ApiService,
              private cs: ContactService,
              private dialog: MatDialog,
              private router: Router) {
    this.document = api.version;
  }

  ngOnInit() {
    this.contact = this.cs.loadLocalContact();
  }

  clearSignature() {
    this.formHB.get('signature').setValue('');
    // this.canvasData = '';
    this.signatureComp.clear();
  }

  exitExpanded() {
    this.signatureComp.clear();
    this.expandedView = false;
    this.signatureComp.toggleExtended(this.expandedView);
  }

  async saveSignature() {
    const image = this.formHB.get('signature').value;
    const terms = this.formHB.get('sendCopy').value;
    this.api.saveContact(this.contact, image, terms)
      .subscribe({
        next: (response: any) => {
          this.dialog
            .open(AcceptComponent, {
              width: '500px',
              data: {
                title: 'Éxito',
                content: response.message,
                button: 'Aceptar'
              }
            })
            .afterClosed()
            .pipe(first())
            .subscribe(() => {
              this.cs.clear();
              this.router.navigate(['/']);
            });
        },
        error: (error: HttpErrorResponse) => {
          this.showError(error?.error?.message ?? 'Se ha presentado un error');
        }
      });
  }

  private showError(message) {
    this.dialog.open(AcceptComponent, {
      width: '500px',
      data: {
        title: 'Error',
        content: message,
        button: 'Aceptar'
      }
    });
  }

  get signature(): string | undefined {
    const sign = this.formHB.get('signature').value;
    return sign !== '' ? sign : undefined;
  }

  toggleExtended(status) {
    this.expandedView = status;
    // if (!this.expandedView) {
    //   this.canvasData = this.formHB.get('signature').value
    // }
    this.signatureComp.toggleExtended(status);
  }
}
